import { useFetchLedgers } from 'apiHooks/banking/ledgerFetchHooks';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { LedgersResponse } from 'types/api/banking/types';
import { Ledger } from './ledger.types';

const toLedgersArray = (data: LedgersResponse): Ledger[] => {
  return data.map((rawLedger) => ({
    createdAt: rawLedger.created_at,
  }));
};

export const useLedgers = (
  customerUuid: string,
  submissionUuid: string
): UseGenericQueryResponse<Ledger[]> =>
  useGenericFeatureQuery(
    useFetchLedgers,
    (data) => data && toLedgersArray(data),
    customerUuid,
    submissionUuid
  );
