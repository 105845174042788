import React from 'react';
import {
  Box,
  Text,
  Table,
  formatDateTimeString,
  Subheading,
} from '@forward-financing/fast-forward';
import { DataMerch } from './dataMerchTypes.types';

export type DataMerchTableProps = {
  dataMerchData?: DataMerch;
};

export const DataMerchTable = ({
  dataMerchData,
}: DataMerchTableProps): JSX.Element => {
  if (!dataMerchData) {
    return <Text>No report available</Text>;
  }
  return (
    <Box>
      <Subheading>{dataMerchData.legalName}</Subheading>
      <Table caption={'Data Merch'}>
        <Table.Head>
          <Table.ColumnHeader>Category</Table.ColumnHeader>
          <Table.ColumnHeader>Note</Table.ColumnHeader>
          <Table.ColumnHeader>Created At</Table.ColumnHeader>
          <Table.ColumnHeader>Added By</Table.ColumnHeader>
          <Table.ColumnHeader>FEIN</Table.ColumnHeader>
          <Table.ColumnHeader>DBA</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {dataMerchData.notes.map((row) => (
            <Table.Row key={`${row.note}${row.category}${row.createdAt}$`}>
              <Table.Cell>{row.category}</Table.Cell>
              <Table.Cell>{row.note}</Table.Cell>
              <Table.Cell>
                {row.createdAt && formatDateTimeString(row.createdAt)}
              </Table.Cell>
              <Table.Cell>{row.addedBy}</Table.Cell>
              <Table.Cell>{dataMerchData.fein}</Table.Cell>
              <Table.Cell>
                {dataMerchData.dba || dataMerchData.legalName}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
