import React, { useState, useEffect } from 'react';
import {
  Box,
  Banner,
  Flex,
  Loading,
  Tabs,
  Card,
  IconButton,
} from '@forward-financing/fast-forward';
import { noop } from 'lodash';

import { usePreventNavigation } from 'hooks/usePreventNavigation.hook';
import { toError } from 'helpers/errorUtils';
import { FFLogger } from 'api/LogClient';
import { featureFlags } from 'helpers/featureFlags';
import { LedgerContainer } from 'components/Ledger/LedgerContainer';
import { useGetApiFeatureFlagWithIdentifier } from 'apiHooks/underwriting/featureFlagFetchHooks';
import { useLedgers } from 'components/Ledger/LedgerFetchHooks';
import { NewDealScoreComposite } from '../DealScoring.types';
import { V6ScoreDisplay } from '../V6ScoreDisplay/V6ScoreDisplay';
import {
  ManagerScoreForm,
  NewDealScore,
  ScoreForm,
  UnderwriterScoreForm,
  NewDealAutomatedScore,
} from './NewDealScoring.types';
import { NewDealScoringProvider } from './NewDealScoringContext';
import { NewDealScoringComposite } from './NewDealScoringComposite';
import { INITIAL_NOTES_TEXT } from './UnderwriterScoring/UnderwriterScoring';
import {
  fetchNewDealScore,
  fetchAutomatedSystemScore,
  createScore,
  updateScore,
} from './NewDealScoringFetchUtils';
import { useRefreshNewDealCompositeScore } from './NewDealScoringFetchHooks';
export interface NewDealScoringContainerProps {
  submissionUuid: string;
  children: React.ReactNode;
  compositeScore?: NewDealScoreComposite;
  customerUuid: string;
}

export const NewDealScoringContainer = ({
  submissionUuid,
  customerUuid,
  children,
  compositeScore,
}: NewDealScoringContainerProps): JSX.Element => {
  const [scoreIsLoading, setScoreIsLoading] = useState(true);
  const [systemScoreIsLoading, setSystemScoreIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data: ledgersData } = useLedgers(customerUuid, submissionUuid);

  const handleAddNewLedger = (): void => {
    // Will use actual API call when FE-1837 is merged,
    // do nothing for now
    noop();
  };

  /**
   * This is to fetch a single feature flag with identifier.
   * This v6 score feature and the new pricing feature need to be released together.
   * The new Pricing feature is using a feature flag but instead of turning it on/off
   * at the user level, it will be toggled at the customer level, that's why we need
   * to send customer uuid, to check if the flag is on for the current customer
   */
  const ua_ba_pricing_suggestion_a_b_test = useGetApiFeatureFlagWithIdentifier(
    'ua_ba_pricing_suggestion_a_b_test',
    customerUuid
  );

  const [hasUnsavedUnderwriterChanges, setHasUnsavedUnderwriterChanges] =
    useState(false);
  const [hasUnsavedManagerChanges, setHasUnsavedManagerChanges] =
    useState(false);
  const [isUnscored, setIsUnscored] = useState(true);

  const [response, setResponse] = useState<NewDealScore | null>(null);
  const [systemScore, setSystemScore] = useState<
    NewDealAutomatedScore | undefined
  >(undefined);
  const [score, setScore] = useState<ScoreForm>({
    ownerRisk: 0,
    businessRisk: 0,
    qualityOfCashFlows: 0,
    onlinePresence: null,
    dealSpecificFactors: null,
    underwriterNote: '',
    pricingRating: 0,
    processRating: 0,
    managerNote: '',
    checklistResponses: [],
  });
  const [isAutosaving, setIsAutosaving] = useState(false);

  usePreventNavigation(
    hasUnsavedUnderwriterChanges || hasUnsavedManagerChanges
  );

  const [
    refreshCompositeScore,
    {
      newDealScoreCompositeRefreshed,
      error: errorRefreshNewDealCompositeScore,
      loading: loadingRefreshNewDealCompositeScore,
    },
  ] = useRefreshNewDealCompositeScore(submissionUuid);

  const scoreData =
    newDealScoreCompositeRefreshed || compositeScore || undefined;

  useEffect(() => {
    const fetchScore = async (): Promise<void> => {
      try {
        const newDealScoreResponse = await fetchNewDealScore(submissionUuid);

        setResponse(newDealScoreResponse);
        resetForm(newDealScoreResponse);
        setIsUnscored(newDealScoreResponse.lastUpdated === null);
      } catch (e: unknown) {
        const error = toError(e);
        FFLogger.error(error);
        setErrorMessage(error.message);
      } finally {
        setScoreIsLoading(false);
      }
    };

    void fetchScore();
  }, [submissionUuid]);

  useEffect(() => {
    const fetchSystemScore = async (): Promise<void> => {
      try {
        const suggestedScoreResponse = await fetchAutomatedSystemScore(
          submissionUuid
        );

        setSystemScore(suggestedScoreResponse);
      } catch (e: unknown) {
        const error = toError(e);
        FFLogger.error(error);
        setErrorMessage(error.message);
      } finally {
        setSystemScoreIsLoading(false);
      }
    };

    void fetchSystemScore();
  }, [submissionUuid]);

  const handleScoreChange = (newScore: ScoreForm): void => {
    setScore({
      ...score,
      ...newScore,
    });
    // null out the error message on any change
    setErrorMessage(null);
  };

  const handleScoreUpdate = async (inputScore: ScoreForm): Promise<void> => {
    setIsAutosaving(true);
    try {
      const newScore = await (isUnscored
        ? createScore(submissionUuid, { ...score, ...inputScore })
        : updateScore(submissionUuid, { ...score, ...inputScore }));

      resetForm(newScore);
      setResponse(newScore);
      setIsUnscored(false);
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error);
      setErrorMessage(error.message);
    }
    setIsAutosaving(false);
  };

  const handleUnderwriterTextAreaChange = (
    newScore: UnderwriterScoreForm
  ): void => {
    handleScoreChange({
      ...score,
      ...newScore,
    });
    setHasUnsavedUnderwriterChanges(true);
  };

  const handleManagerTextAreaChange = (newScore: ManagerScoreForm): void => {
    handleScoreChange({
      ...score,
      ...newScore,
    });
    setHasUnsavedManagerChanges(true);
  };

  // We have to pass the response here because we're using it in
  // the .then above, which is called before `response` is set in state
  const resetForm = (responseData: NewDealScore): void => {
    setScore({
      ownerRisk: responseData.ownerRisk || 0,
      businessRisk: responseData.businessRisk || 0,
      qualityOfCashFlows: responseData.qualityOfCashFlows || 0,
      onlinePresence: responseData.onlinePresence,
      dealSpecificFactors: responseData.dealSpecificFactors,
      underwriterNote: responseData.underwriterNote || INITIAL_NOTES_TEXT,
      pricingRating: responseData.pricingRating || 0,
      processRating: responseData.processRating || 0,
      managerNote: responseData.managerNote || '',
      checklistResponses: responseData.checklistResponses || [],
    });

    setHasUnsavedUnderwriterChanges(false);
    setHasUnsavedManagerChanges(false);
    setErrorMessage(null);
  };

  const onSubmit = async (): Promise<void> => {
    setIsAutosaving(true);
    try {
      const newScore = await (isUnscored
        ? createScore(submissionUuid, score)
        : updateScore(submissionUuid, score));

      resetForm(newScore);
      setResponse(newScore);
      setIsUnscored(false);
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error);
      setErrorMessage(error.message);
    }
    setIsAutosaving(false);
  };

  const underwriterScoreAttributes = [
    score.ownerRisk,
    score.businessRisk,
    score.qualityOfCashFlows,
  ];

  const isPartialSave =
    !isUnscored &&
    !hasUnsavedUnderwriterChanges &&
    underwriterScoreAttributes.some((scoreValue) => scoreValue === 0);

  if (scoreIsLoading || systemScoreIsLoading) {
    return (
      <Card collapsible title="UW Deal Score" initialOpenState={false}>
        <Loading />
      </Card>
    );
  }

  // Quite some code is repeated below because we want to show a tabbed
  // layout if the `show_new_deal_composite_score` feature flag is on, and
  // the original non-tabbed layout if it's off. There might be a cleaner way
  // of doing this, but this is temporary and should be cleaned up when we
  // remove the feature flag and the old layout.
  if (featureFlags.show_new_deal_composite_score) {
    return (
      <Card collapsible title="UW Deal Score" initialOpenState={false}>
        <Box m={3}>
          {errorMessage && <Banner dismissable={false}>{errorMessage}</Banner>}

          <Tabs
            defaultValue={
              ua_ba_pricing_suggestion_a_b_test &&
              featureFlags.show_v6_score_display
                ? 'v6Score'
                : 'uwScore'
            }
          >
            <Tabs.List>
              {ua_ba_pricing_suggestion_a_b_test &&
                featureFlags.show_v6_score_display && (
                  <Tabs.Trigger value="v6Score">V6 Score</Tabs.Trigger>
                )}

              <Tabs.Trigger value="uwScore">
                <Flex gap={2} alignItems="center">
                  UW Score
                </Flex>
              </Tabs.Trigger>

              <Tabs.Trigger value="newDealCompositScore">
                New Deal Composite Score
              </Tabs.Trigger>

              {featureFlags.show_new_ledgers_in_UA ? (
                <>
                  <IconButton
                    icon={'plus-circle'}
                    label="New Ledger"
                    onClick={handleAddNewLedger}
                  />

                  {ledgersData &&
                    ledgersData.map((ledger) => (
                      <Tabs.Trigger
                        value={ledger.createdAt}
                        key={ledger.createdAt}
                      >
                        {ledger.createdAt}
                      </Tabs.Trigger>
                    ))}
                </>
              ) : null}
            </Tabs.List>

            {ua_ba_pricing_suggestion_a_b_test &&
              featureFlags.show_v6_score_display && (
                <Tabs.Content value="v6Score">
                  <V6ScoreDisplay submissionUuid={submissionUuid} />
                </Tabs.Content>
              )}

            <Tabs.Content value="uwScore">
              <NewDealScoringProvider
                value={{
                  response,
                  score,
                  systemScore,
                  handleScoreUpdate,
                  handleUnderwriterTextAreaChange,
                  handleManagerTextAreaChange,
                  isUnscored,
                  isPartialSave,
                  hasUnsavedUnderwriterChanges,
                  hasUnsavedManagerChanges,
                  resetForm,
                  onSubmit,
                  isAutosaving,
                }}
              >
                {children}
              </NewDealScoringProvider>
            </Tabs.Content>

            <Tabs.Content value="newDealCompositScore">
              <NewDealScoringComposite
                scoreData={scoreData}
                refreshScoreData={refreshCompositeScore}
                loading={loadingRefreshNewDealCompositeScore}
                errorRefresh={errorRefreshNewDealCompositeScore}
              />
            </Tabs.Content>

            {featureFlags.show_new_ledgers_in_UA ? (
              <>
                {ledgersData &&
                  ledgersData.map((ledger) => (
                    <Tabs.Content
                      value={ledger.createdAt}
                      key={ledger.createdAt}
                    >
                      <LedgerContainer ledger={ledger} />
                    </Tabs.Content>
                  ))}
              </>
            ) : null}
          </Tabs>
        </Box>
      </Card>
    );
  }

  // Branch for the non-tabbed original layout.
  return (
    <Card collapsible title="UW Deal Score" initialOpenState={false}>
      <Box m={3}>
        {errorMessage && <Banner dismissable={false}>{errorMessage}</Banner>}
        <NewDealScoringProvider
          value={{
            response,
            score,
            systemScore,
            handleScoreUpdate,
            handleUnderwriterTextAreaChange,
            handleManagerTextAreaChange,
            isUnscored,
            isPartialSave,
            hasUnsavedUnderwriterChanges,
            hasUnsavedManagerChanges,
            resetForm,
            onSubmit,
            isAutosaving,
          }}
        >
          {children}
        </NewDealScoringProvider>
      </Box>
    </Card>
  );
};
