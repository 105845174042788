import { useCallback } from 'react';
import { fetchLedgers, updateLedger } from 'api/banking/ledgerFetchUtils';
import {
  UseGenericMutationResponse,
  UseGenericQueryResponse,
  useGenericMutation,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { LedgersResponse, UpdateLedgerBody } from 'types/api/banking/types';

export interface UseUpdateLedgerArgs {
  submissionUuid: string;
  updateBody: UpdateLedgerBody;
}

export type UseUpdateLedgerResponse = UseGenericMutationResponse<
  { success: boolean },
  UseUpdateLedgerArgs
>;

const updateLedgerWithArgs = async (
  args: UseUpdateLedgerArgs
): Promise<{ success: boolean }> =>
  updateLedger(args.submissionUuid, args.updateBody);

export const useApiUpdateLedger = (): UseUpdateLedgerResponse => {
  return useGenericMutation(updateLedgerWithArgs);
};

export const useFetchLedgers = (
  customerUuid: string,
  submissionUuid: string
): UseGenericQueryResponse<LedgersResponse> => {
  const fetchFunction = useCallback(() => {
    return fetchLedgers(customerUuid, submissionUuid);
  }, [customerUuid, submissionUuid]);

  return useGenericQuery(fetchFunction);
};
