import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { BANK_BASE_URL } from 'constants/globals';
import { LedgersResponse, UpdateLedgerBody } from 'types/api/banking/types';

export const updateLedger = async (
  submissionUuid: string,
  updateBody: UpdateLedgerBody
): Promise<{ success: boolean }> => {
  const url = new URL(`/api/v2/ledgers/${submissionUuid}`, BANK_BASE_URL());

  const response = await makeInternalAPIRequest<void, UpdateLedgerBody>(
    url,
    'PATCH',
    updateBody
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update ledger');
  }

  return { success: true };
};

export const fetchLedgers = async (
  customerUuid: string,
  submissionUuid: string
): Promise<LedgersResponse> => {
  const url = new URL(
    `api/v3/prospective_merchants/${customerUuid}/opportunities/${submissionUuid}/ledgers`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LedgersResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Ledgers');
  }

  return response.json();
};
